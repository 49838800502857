<template>
    <div v-loading.fullscreen.lock="loading" :element-loading-text="loadingText"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" class="bt">

        <template v-if="!isFromChildTable">
            <!--  New Logic Display the button on both view and execute  ***START**** -->
            <div v-if="buttonStatus">
                <span style="display:none;">{{ Number(parseFloat(getValue).toFixed(2)) }} {{ isVisible }}</span>
                <el-button type="success" round @click="proceed" :disabled="!payAmount || readonly()"
                    :class="{ 'disabled-field': checkReadonly() }">
                    {{ data.label }} {{ getCurrencySymbol(data.validations.currency) }}
                    {{Number(parseFloat(payAmount).toFixed(2)) }} </el-button>
                <span @click="paymentHistory"> <i class="el-icon-info"></i></span>
            </div>

            <div v-else>
                <span style="display:none;">{{ Number(parseFloat(getValue).toFixed(2)) }} {{ isVisible }}</span>
                <el-row>
                    <el-col :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24">
                        <span>
                            <label for="text" v-if="hasLabel" :style="getLabelStyles">
                                {{ data.label }}
                            </label>
                        </span>
                        <span v-if="data.validations.required && hasLabel" style="color: red; font-weight: bold">
                            *
                        </span>
                        <span v-if="data.description" style="color: #c0c4cc; font-weight: bold">
                            <el-tooltip :content="data.description" placement="top" effect="dark">
                                <i class="el-icon-info"></i>
                            </el-tooltip>
                        </span>
                    </el-col>
                </el-row>

                <span v-if="isView">
                    {{ form[data.key] }}
                    <span style="display: none">
                        {{ Number(parseFloat(getValue).toFixed(2)) }}
                        {{ isVisible }}
                    </span>
                </span>
                <span v-else>
                    <span v-if="form && data.key && form[data.key]"> {{ form[data.key] }}</span><span v-else> NO ACTION
                        DONE</span>

                    <span style="display: none">
                        {{ Number(parseFloat(getValue).toFixed(2)) }}
                        {{ isVisible }}
                    </span>
                </span>
                <span @click="paymentHistory"> <i class="el-icon-info"></i></span>
            </div>
        </template>
        <template v-else>

            <span style="display:none;">{{ Number(parseFloat(getValue).toFixed(2)) }} {{ isVisible }}</span>
            <el-button type="success" v-if="buttonStatus" round @click="proceed"
                :class="{ 'disabled-field': checkReadonly() }">
                {{ data.label }} {{ getCurrencySymbol(data.validations.currency) }}
                {{ Number(parseFloat(payAmount).toFixed(2)) }}
                <!-- {{childEntitySettings.amount}} -->
            </el-button>
            <span v-else>
                {{ form[data.key] }}
            </span>
        </template>

        <!--  New Logic Display the button on both view and execute  ***END**** -->
        <dialog-component v-loading="dialogLoader" :title="modalTitle" :visible="historyPopUp"
            @before-close="closePopUps" :containerMinWidth="getIsMobile ? '98%' : '75vw'" :containerMaxHeight="'80vh'"
            :isShowFooter="false">
            <div style="float:right" v-if="getPaymentActions">
                <span class="badge badge-primary" style="cursor:pointer" @click="getPaymentThroughHistory">Get
                    Payment</span>
            </div>
            <div style="float:right" v-if="getPaymentActions">
                <span class="badge badge-info" style="cursor:pointer"
                    @click="getPaymentLink(entityDataId, data.key)">Get
                    Payment Link</span> &nbsp;&nbsp;
            </div>
            <br /><br />

            <el-table :data="form[data.key + '_history']">
                <el-table-column property="txn_id" label="Transaction Id"></el-table-column>
                <el-table-column property="paymentDate" label="Date" width="getIsMobile ? 150 : 250">
                    <template slot-scope="scope">
                        {{ $moment.utc(scope.row.paymentDate).local().format('YYYY-MM-DD hh:mm:ss A') }}
                    </template>
                </el-table-column>
                <el-table-column property="gateway" label="Gateway">
                    <template slot-scope="scope"><span
                            :class="getBadgeType(scope.row.gateway, scope.row.status == 'Refunded' ? true : false)"> {{
        scope.row.gateway }} {{ scope.row.paymentType }}</span> </template>
                </el-table-column>
                <!-- <el-table-column property="paymentType" label="Status">
                <template slot-scope="scope"><span :class="getBadgeType(scope.row.gateway)"> {{ scope.row.gateway }} - {{ scope.row.paymentType }}</span> </template>
            </el-table-column> -->
                <el-table-column property="paidAmount" label="Amount">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 'Refunded'">
                            {{ getCurrencySymbol(scope.row.currency) }}&nbsp;{{ scope.row.amountRefunded }}
                        </span>
                        <span v-else>
                            {{ getCurrencySymbol(scope.row.currency) }}&nbsp;{{ scope.row.paidAmount }}
                            &nbsp;&nbsp;<span @click="refundInit(scope.row)" v-if="getPaymentActions"
                                class="badge badge-danger" style="cursor:pointer;">Refund</span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column>
                    <template slot-scope="scope"><span :class="getStatus(scope.row.status).color">
                            {{ getStatus(scope.row.status).label }}</span></template>
                </el-table-column>
            </el-table>
        </dialog-component>
        <dialog-component v-loading="dialogLoader" :title="modalTitle" :visible="partialPaymentVisible"
            @before-close="handleClose" :containerWidth="getIsMobile ? '100%' : '50vw'" :containerMaxHeight="'70vh'"
            :isShowFooter="false" :cancelConfirmation="true" prompt="Are you sure to cancel the transaction? ">
            <template>
                <br />
                <div v-if="data.validations.partialPayment">
                    <el-radio v-model="partialPaymentStatus" :label="1">Pay Full amount</el-radio>
                    <el-radio v-model="partialPaymentStatus" :label="0">Pay partial amount</el-radio>
                    <br /> <br />
                    <el-input-number v-if="!partialPaymentStatus" :precision="2" v-model="payAmount" :min="1"
                        :max="currentTxnAmount" placeholder="Enter other amount " />
                </div>
                <div>
                    <div>
                        <h4>Make Payment Through</h4>
                    </div>
                    <hr />
                    <span class="bt">
                        <el-row :gutter="10">
                            <el-col :sm="3" :md="4" v-for="(listedPG, index) in data.validations.paymentSystems"
                                :key="index">
                                <span class="mtb-1 mlr-1">
                                    <button type="button" class="btn btn-sm btn-block btn-outline-default m-0"
                                        @click="save_and_init_payment_with_gateway(listedPG, payAmount)"
                                        v-if="getNameOfPG(listedPG).pgType">
                                        <component :is="getGatewayIcon(getNameOfPG(listedPG).pgType)" class="icons" />
                                    </button>
                                </span>
                            </el-col>
                        </el-row>
                    </span>
                </div>
            </template>
        </dialog-component>
        <dialog-component v-loading="dialogLoader" title="Make Payment" :visible="newPaymentScreen"
            :containerWidth="getIsMobile ? '98%' : '60vw'" :containerMaxHeight="getIsMobile ? '95vh' : '80vh'"
            :isShowFooter="false" @before-close="handleClosePaymentPopup">
            <br />
            <PayEsign :pgAcId="getIntegratedList._id" :txnAcId="tempStorage" :pgType="tempStorage.pgType"
                :currency="data.validations.currency" :amount="finalAmount" :transactionAccount="tempStorage"
                :data="data" :customerAddress="customerInfo" :entityInfo="getTemplatesData" :paymentApiUrl="apiUrl"
                :category="category" :isChildDataTable="this.isFromChildTable"
                :transactions="this.childEntitySettings && this.childEntitySettings.dataSet ? this.childEntitySettings.dataSet : []" />
            <br />
        </dialog-component>

        <dialog-component v-loading="dialogLoader" title="Refund" :visible="refundPayment"
            :containerMinWidth="getIsMobile ? '98%' : '80vw'" :containerMaxHeight="'70vh'" :isShowFooter="false"
            @before-close="handleCloseRefundPopup">
            <br />
            <PayRefund :transactionInfo="selectedTransaction" :data="data" :templateId="currentStep.template_id"
                :entityDataId="entityDataId" v-if="currentStep && currentStep.template_id"/>
            <br />
        </dialog-component>

        <dialog-component v-loading="dialogLoader" title="Payment Link" :visible="paymentLinkGeneration"
            @before-close="closePopUps" :containerWidth="getIsMobile ? '98%' : '50vw'" :containerMaxHeight="'80vh'"
            :isShowFooter="false">
            <br />
            <div v-loading="PLloading" :element-loading-text="PLloadingText" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" class="bt row" style="min-height:100px;">
                <div class="col-md-12">
                    <span v-if="paymentLinkStatus.success">
                        URL<br>
                        <hr />
                        <span id="paymentLinkURL">{{ paymentLinkStatus.paymentLink }}</span>
                        <i class="el-icon-copy-document" @click="copyPaymentLink"></i>
                    </span>
                    <span v-else-if="paymentLinkStatus.error">
                        {{ paymentLinkStatus.message }}
                    </span>
                </div>
            </div>
            <br />

        </dialog-component>
    </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import {
    mapGetters
} from "vuex";
import Config from "../../../config/app";
import axios from "@/config/axios";
import moment from "moment";
import systemAxios from "@/config/axios";
import Swal from "sweetalert2";
import * as PaymentIcons from "../PaymentIcons/index";
import PayEsign from "../../payment-link/cc.vue"
import PayRefund from "../../payment-link/refund.vue";
import { sleep, urlParser } from "@/utils/utility";


var currencyToSymbolMap = require("@/components/payment-link/CurrencySymbols.json");
export default {
    name: "templates-formComponentsExecute-SingleLineTextExecute",
    components: { PayEsign, PayRefund },
    mixins: [userPermissionsHelper],
    props: [
        "data",
        "form",
        "isView",
        "hasLabel",
        "isFromDocument",
        "fromRepeatable",
        "checkIsDisabled",
        "profilePage",
        "entityDataId",
        "entityData",
        "currentEntity",
        "currentStep",
        "isFromEntity",
        "isFromFormBuilder",
        "fieldsData",
        "templateData",
        "isApplicationUserSide",
        "isFromChildTable",
        "fromDT",
        "rowIndex",
        "childEntitySettings",
        "childEntityDataIds",
    ],
    async mounted() {
        this.loading = true;
        this.loadingText = "Please Wait....... ";
        this.localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (
            this.$route.name == "ApEntityExecute" ||
            this.$route.name == "ApPreviewEntityData"
        ) {
            this.type = 'APP_USER';
            this.category = 'ENTITY'
        }
        else if (this.isFromFormBuilder) {
            this.type = 'FORM_BUILDER_TEMPLATE';
            this.category = 'FORM_BUILDER';
            if (this.currentEntity && this.currentEntity._id) {
                this.type = "FORM_BUILDER_ENTITY_TEMPLATES";
            }
        }
        if (this.form && this.data.key && this.form[this.data.key + '_history']) {
            // console.log("History Fund")
        }
        else {
            this.$set(this.form, this.data.key + '_history', []);
        }
        this.totalPayAmount = 0;
        if (this.data.default_value && !this.form[this.data.key]) {
            this.$set(this.form, this.data.key, this.data.default_value);
        }
        if (this.data.styles && this.data.styles.labelStyle) {
            if (this.data.styles.labelStyle == "hide") {
                this.showLabel = false;
            }
        }
        this.loading = false;
        if (this.entityDataId) {
            this.getTemplateIdsData();
        }
        this.emitFormBuilderStatus = false;


    },
    computed: {
        ...mapGetters("globalVariables", [
            "getGlobalVariableById"
        ]),
        ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
        ...mapGetters("paymentGateway", [
            "getPlansData",
            "getActivePaymentSystems",
            "getPaymentGatewayInfo",
            "getUpdateSystemInfo",
            "getIntegratedList",
            "getEntityPaymentStatus"
        ]),
        ...mapGetters("formbuilderData", [
            "getNewWorkflowData",
            "getWorkflowTemplateDataAddStatus",
            "getNewFormbuilderData",
            "getUserFormbuilderData",
            "getFormbuilderDataUpdateStatus",
        ]),
        ...mapGetters("templatesData", [
            "getRequiredTemplatesData",
            "getDuplicateDataCheck",
            "getUploadAndUpdateDocument",
            "getTemplatesDataUpdateStatus",
            "getUserTemplateDataUpdateErrors",
            "getNewEntityCreateError",
            "getEntityDataCreateByTemplateStatus",
            "getNewEntityData",
            "getTemplatesData"
        ]),
        ...mapGetters("company", [
            "getCompanyDetails",
            "getUpdateCompany",
            "getCompanyLogo",
            "getSingleCompanyLogo",
            "getContactLoginSlug",
        ]),
        getPaymentActions() {
            // return()=>{
            return this.isApplicationUserSide || this.category === 'FORM_BUILDER' ? false : true;
            // }
        },
        getCurrencySymbol() {
            return (currency) => {
                return currencyToSymbolMap[currency];
            }

        },
        getBadgeType() {
            return (type, status = false) => {
                return type === 'FORTE' ? status ? 'badge badge-danger' : 'badge badge-success' : type === 'SWIREPAY' ? status ? 'badge badge-danger' : 'badge badge-warning' : type === 'RAZORPAY' ? status ? 'badge badge-danger' : 'badge badge-primary' : 'badge badge-info';
            }
        },
        getStatus() {
            return (status) => {
                let successStatus = ["Settled", "Approved", "Complete", "Succeeded", "APPROVED", "SUCCEEDED", "COMPLETE", "SETTLED", "TEST APPROVAL", "Paid", "PAID", "paid"];
                let refundStatus = ['REFUNDED', 'refunded', 'refund', 'Refunded'];
                return successStatus.includes(status) ? { color: 'badge badge-success', label: 'Success' } : refundStatus.includes(status) ? { color: 'badge badge-info', label: 'Refunded' } : { color: 'badge badge-danger', label: 'In-Progress' };

                // return status==='ACTIVE' ? 'badge badge-success' : status==='INACTIVE' ? 'badge badge-warning' : 'badge badge-info' ;

            }
        },
        isVisible() {
            let totalAmount = Number(parseFloat(this.getValue).toFixed(2));
            let totalPaidAmount= Number(parseFloat(this.getTransactionTotal).toFixed(2))
            if (totalAmount - totalPaidAmount > 0) {
                this.updateButtonStatus(true)
                this.updateAmount(totalAmount, totalAmount - totalPaidAmount,this.data.validations.currency , totalPaidAmount)
                return true;
            } else {
                this.updateButtonStatus(false)
                return false;
            }
        },
        getStyle() {
            return this.data.styles &&
                this.data.styles.label_color &&
                !this.fromRepeatable ?
                `height:${this.data.height - 30}px; font-weight: 400; width: ${this.data.width
                }px;` :
                "height: 70px";
        },
        getLabelStyles() {
            return this.data.styles && this.data.styles.label_color ?
                `color: ${this.data.styles.label_color}; ` :
                "font-weight: 700";
        },
        isDefalutPosq() {
            if (this.data.styles && this.data.styles.labelStyle == "right") {
                return false;
            } else {
                return true;
            }
        },
        getValue() {
            if (this.isFromChildTable) {
                let pendingAmount = 0;
                if (this.getTransactionTotal < this.childEntitySettings.amount) {
                    pendingAmount = this.childEntitySettings.amount - this.getTransactionTotal;
                    pendingAmount = Number(parseFloat(pendingAmount).toFixed(2));
                } else if (this.childEntitySettings.amount < this.getTransactionTotal) {
                    //Need to do when the variable value less then the total paid value 
                } else {
                    // Need to Do Else case
                }
                this.updateAmount(this.childEntitySettings.amount, pendingAmount, this.childEntitySettings.currency, this.getTransactionTotal)
                return this.childEntitySettings.amount;
            }
            else if (this.data.validations && this.data.validations.entityVariable && this.data.validations.entityVariable.key) {
                let fields = [{
                    ...this.data.validations.entityVariable
                }];
                let data = {};
                if (Object.keys(this.form).length) {
                    Object.keys(this.form).forEach((key) => {
                        if (key.indexOf("#") > -1) {
                            data[key.split("#")[1]] = this.form[key];
                        } else {
                            if (fields[0].input_type === "CURRENCY") {
                                data[key] = this.form[key];
                            } else if (fields && fields[0]) {
                                data[key] = this.form[key];
                            } else {
                                data[key] = Number(parseFloat(this.form[key]).toFixed(this.data.decimals));
                            }
                        }
                    });
                }
                fields.forEach((fl) => {
                    if (fl.template_id) {
                        data[fl.key] = this.getTemplateValue(fl.template_id, fl.key);
                    } else if (fl.input_type === "CURRENCY") {
                        data[fl.key] = this.form[fl.key] ? this.form[fl.key] : 0;
                        data[fl.key + '_currency'] = this.form[fl.key + '_currency'] ? this.form[fl.key + '_currency'] : 'USD';
                    } else {
                        data[fl.key] = this.form[fl.key] ? this.form[fl.key] : '';
                    }
                });
                let pendingAmount = 0;
                // let totalPaidAmount=this.getTransactionTotal;
                if (this.getTransactionTotal < data[this.data.validations.entityVariable.key]) {
                    pendingAmount = data[this.data.validations.entityVariable.key] - this.getTransactionTotal;
                    pendingAmount = Number(parseFloat(pendingAmount).toFixed(2));
                } else if (data[this.data.validations.entityVariable.key] < this.getTransactionTotal) {
                    //Need to do when the variable value less then the total paid value 
                } else {
                    // Need to Do Else case
                }
                this.updateAmount(data[this.data.validations.entityVariable.key], pendingAmount, this.data.validations.currency, this.getTransactionTotal)
                return data[this.data.validations.entityVariable.key];
            } else {
                return 0;
            }
        },
        getTransactionTotal() {
            if (this.data.validations && this.data.validations.entityVariable && this.data.validations.entityVariable.key && this.data.validations.transactionsCal) {
                let transactions = [];

                if (this.isFromChildTable && this.childEntitySettings && this.childEntitySettings.transactions) {
                    transactions = this.childEntitySettings.transactions;
                }
                else {
                    transactions = this.form && this.data.key && this.form[this.data.key + "_history"];
                }
                // console.log("Transactions",this.data.key,transactions)
                let sum = 0, refunded = 0;
                transactions && transactions.map(txn => {
                    if (txn && txn.paidAmount) {
                        sum = sum + txn.paidAmount
                    }
                    if (txn && txn.amountRefunded) {
                        refunded = refunded + txn.amountRefunded;
                    }
                });
                return Number(parseFloat(sum).toFixed(2)) - Number(parseFloat(refunded).toFixed(2));
            } else {
                return 0;
            }
        },
        getEntityId() {
            return this.currentEntity?._id || this.$route.params.entity_id;
        },
        getNameOfPG() {
            return (pgID) => {
                let pg = this.getIntegratedList?.cred_details?.filter((pg) => pg._id == pgID);
                return pg && pg[0] ? pg[0] : {}
                // return pgID
            }
        },
        getGatewayIcon() {
            return (pgID) => {
                return PaymentIcons[pgID] ? PaymentIcons[pgID] : '';
            }
        }

    },
    data() {
        return {
            /**** For Multiple payments We need to we changed the final amount fro data.validations to below variables  */
            totalPayAmount: 0,
            payAmount: 0,
            currentTxnAmount: 0,
            type: "",
            category: 'ENTITY',
            emitFormBuilderStatus: false,
            declineUrl: '',
            finalAmount: 0,
            apiUrl: Config.PAYMENT_GATEWAY_URL,
            // gatewayIcon: '',
            localTimezone: '',
            paymentDialog: false,
            paymentLink: '',
            dialogVisible: false,
            isList: false,
            showLabel: true,
            isDefalutPos: true,
            loading: false,
            entityRepeatableTemplateData: {},
            hasRepeatableTemplate: false,
            entitiesData: [],
            loadingText: "Loading ....!",
            buttonStatus: false,
            partialPaymentVisible: false,
            partialPaymentStatus: 1,
            tempStorage: {},
            selectedInvoice: null,

            dialogLoader: false,
            modalTitle: '',
            historyPopUp: false,
            newPaymentScreen: false,
            refundPayment: false,
            selectedTransaction: {},

            paymentLinkGeneration: false,
            PLloading: false,
            PLloadingText: 'Loading .....',
            paymentLinkStatus: {},
            customerInfo: {
                email: '',
                name: '',
                address: '',
                city: '',
                state: '',
                country: '',
                zip_code: ''
            }
        };
    },
    created() {
        this.$bus.$on("closePopUPsAfterPayMentFromFormBuilder", () => {
            this.newPaymentScreen = false;
            this.refundPayment = false;
            this.partialPaymentVisible = false;
            this.$bus.$off("closePopUPsAfterPayMentFromFormBuilder")
        });
        this.$bus.$on(`initPayButton_${this.data.key}`, (data) => {
            if (data) {
                this.paymentActionAfterEntityDataCreated(data.apiKey, data.paymentSystem, data.dataId, data.location, {}, data.entity_id, data.template_id);
                this.$bus.$off(`initPayButton_${this.data.key}`)
            }
        });

        this.$bus.$on(`initTemplatePayButton_${this.data.key}`, (data) => {
            if (data) {
                this.paymentActionAfterEntityDataCreated(data.apiKey, data.paymentSystem, '', data.location, {}, '', data.template_id, true, data.formBuilderDataId, data.formbuilder_details_id);
                this.$bus.$off(`initTemplatePayButton_${this.data.key}`)
            }
        });
    },

    methods: {
        async refundInit(transactionInfo) {
            console.log("Info", transactionInfo);
            this.selectedTransaction = transactionInfo;
            this.refundPayment = true;
        },
        async getPaymentLink(entityId, paymentKey) {
            console.log("Payment", entityId, paymentKey)

            this.PLloading = true;
            this.PLloadingText = 'Loading .....';
            this.paymentLinkStatus = {}
            this.paymentLinkGeneration = true;

            try {
                this.PLloadingText = 'Generating Payment Link....Please wait a while .....';
                let apiResponse = await systemAxios.get(`/getPaymentLink/${this.entityDataId}/${this.currentStep.template_id}/${this.data.key}`);
                this.paymentLinkStatus = apiResponse.data;
                this.PLloading = false;
                this.PLloadingText = "Loading"
            } catch (err) {
                console.log(err)
                this.paymentLinkStatus = { message: err.message || 'Failed to generate Link', error: true };
                this.PLloading = false;
                this.PLloadingText = "Loading"
            }
        },
        async copyPaymentLink() {
            var copyText = document.getElementById("paymentLinkURL");
            navigator.clipboard.writeText(copyText.innerHTML);
            this.$message.success("Payment Url copied");
        },
        async getPaymentThroughHistory() {

            this.$confirm(`Are you sure you want to initiate the transaction for ${this.data.validations.currency} ${this.payAmount}? `, "Warning", {
                confirmButtonText: "Yes, Proceed",
                cancelButtonText: "Cancel",
            }).then(async () => {

                this.loading = true;
                this.loadingText = "Please wait we are fetching saved details and validating."

                try {
                    let apiResponse = await systemAxios.get(`/getPayment/${this.entityDataId}/${this.currentStep.template_id}/${this.data.key}`);
                    apiResponse.data.success ? this.$message.success(apiResponse.data.message) : this.$message.error(apiResponse.data.message);

                    // this.$notify.success({
                    //     title: apiResponse.data.success ? "Success" : "Error",
                    //     message: apiResponse.data.message,
                    // });
                    this.loading = false;
                    this.loadingText = "Loading"
                } catch (err) {
                    console.log(err)
                    this.$notify.error({
                        title: "Error",
                        message: err.message,
                    });
                    this.loading = false;
                    this.loadingText = "Loading"
                }
            });
        },
        handleClose() {
            this.loading = true;
            this.partialPaymentVisible = false;
            this.payAmount = this.currentTxnAmount;
            this.dialogLoader = false;
            this.historyPopUp = false;
            this.modalTitle = '';
            this.loading = false;
        },
        handleCloseRefundPopup() {
            this.loading = true;
            this.loadingText = "Loading";
            this.refundPayment = false;
            this.loading = false;
        },
        handleClosePaymentPopup() {
            this.loading = true;
            this.loadingText = "Loading";
            this.newPaymentScreen = false;
            if (this.declineUrl && this.category != 'FORM_BUILDER') {
                window.location.href = urlParser(this.declineUrl);
            }
            this.loading = false;
        },
        onLoad(frame) {
            console.log(frame)
        },
        updateButtonStatus(status = false) {
            this.buttonStatus = status;
        },
        updateAmount(fullAmount, balanceAmount, currency, paidAmount) {
            this.data.validations.totalAmount = fullAmount;
            this.totalPayAmount = fullAmount;
            this.data.validations.payAmount = balanceAmount ? balanceAmount : 0;
            this.payAmount = balanceAmount ? balanceAmount : 0;
            this.data.validations.currency = currency ? currency : this.data.validations.currency;
            if (balanceAmount) {
                this.updateButtonStatus(true)
            }

            if (paidAmount > 0 && paidAmount != fullAmount) {
                this.$set(this.form, this.data.key, "PARTIALLY PAID");
            }
            else if (paidAmount > 0 && fullAmount <= paidAmount) {
                this.$set(this.form, this.data.key, "PAID");
            }
            else if (paidAmount == 0) {
                this.$set(this.form, this.data.key, "NO ACTION DONE");
            }
        },
        getTemplateValue(templateId, key) {
            let value = 0;
            if (this.getTemplatesData && this.getTemplatesData.length) {
                this.getTemplatesData.forEach(item => {
                    if (item.template_id == templateId) {
                        value =
                            item.template_data && item.template_data[key] ?
                                item.template_data[key] :
                                "";
                    }
                });
                return value ? value : "";
            }
        },
        async getTemplateIdsData() {
            let templateIds = [];

            let fields = [{
                ...this.data.validations.entityVariable
            }];
            fields.forEach(element => {
                if (element?.template_id) {
                    if (templateIds.indexOf(element.template_id) < 0) {
                        templateIds.push(element.template_id);
                    }
                }
            });
            let params = {
                template_ids: templateIds,
                entity_data_id: this.entityDataId,
                status: "ACTIVE"
            };
            await this.$store.dispatch("templatesData/fetchUserTemplateData", params);
        },
        async proceed() {
            if (this.checkIsDisabled) {
                return;
            }
            this.loading = true;
            this.loadingText = "Fetching gateway process....";
            await this.$store.dispatch("paymentGateway/fetchInitSystemsData", "Id");
            if (this.getIntegratedList && this.getIntegratedList.cred_details) {
                this.currentTxnAmount = this.payAmount;
                if ((this.data.validations.partialPayment) || (this.data.validations.paymentSystems && this.data.validations.paymentSystems.length > 1)) {
                    this.partialPaymentVisible = true;
                    this.modalTitle = `Payment request of  ${this.data.validations.currency} ${this.payAmount}`;
                    this.loading = false;
                }
                else {
                    this.save_and_init_payment_with_gateway(this.data.validations.paymentSystems[0], this.payAmount);
                }

            }

        },
        async prepareCustomerInfo(config) {
            let customerInfo = {};
            await this.getTemplatesData.map(async (template) => {
                const keys = Object.keys(template.template_data);
                await keys.forEach(key => {
                    if (template.template_data[key]) {
                        customerInfo[`${template.template_id}#${key}`] = template.template_data[key]
                    }
                    else {
                        customerInfo[`${template.template_id}#${key}`] = '';
                    }
                })
            });
            return {
                email: customerInfo[config.email] ? customerInfo[config.email] : '',
                name: customerInfo[config.name] ? customerInfo[config.name] : '',
                address: customerInfo[config.address] ? customerInfo[config.address] : '',
                city: customerInfo[config.city] ? customerInfo[config.city] : '',
                state: customerInfo[config.state] ? customerInfo[config.state] : '',
                country: customerInfo[config.country] ? customerInfo[config.country] : '',
                zip_code: customerInfo[config.zipCode] ? customerInfo[config.zipCode] : ''
            }

        },
        async save_and_init_payment_with_gateway(gatewayId, amount) {
            try {
                //Proceed To check The Data
                this.loading = true;
                this.partialPaymentVisible = false;
                this.finalAmount = amount
                this.loadingText = "Initializing the process....";
                if (this.getIntegratedList && this.getIntegratedList.cred_details) {
                    let paymentSystem = this.getIntegratedList.cred_details.filter(
                        (e) => e._id == gatewayId
                    );
                    let bytes = await this.$CryptoJS.AES.decrypt(paymentSystem[0].crypto, paymentSystem[0].ivKey);
                    let decryptedData = JSON.parse(bytes.toString(this.$CryptoJS.enc.Utf8));

                    /**Set The variable default values - Start */
                    this.$set(this.form, this.data.key, "INITIATED");
                    if (this.form && this.data.key && this.form[this.data.key + '_history'] && this.form[this.data.key + '_history'].length) {
                        if (this.form[this.data.key + '_history'].length) {
                            this.$set(this.form, this.data.key, "PARTIALLY PAID");
                        } else {
                            this.$set(this.form, this.data.key, "INITIATED");
                        }
                        //do nothing
                    } else {
                        // this.form[this.data.key + '_history']=[];
                        this.$set(this.form, this.data.key + '_history', []);
                        // this.form[this.data.key + '_history_total']=0;
                        this.$set(this.form, this.data.key, "INITIATED");
                    }
                    /**Set The variable default values - End */

                    let paymentAccountInfo = {
                        ...paymentSystem[0],
                        ...decryptedData,
                        pg_account_id: this.getIntegratedList._id,
                        transactionAccountId: gatewayId,
                        key: this.data.key,
                        updateDataKey: this.data.validations.entityVariable.key
                    }

                    if (this.entityDataId && this.entityDataId.length) {

                        this.loading = true;
                        this.loadingText = "Data is saving.... Please wait....."
                        let customer = {};
                        if (this.currentEntity?.paymentConfiguration) {
                            customer = await this.prepareCustomerInfo(this.currentEntity.paymentConfiguration);

                        } else if (this.currentEntity && this.currentEntity.entity_type === "INDIVIDUAL") {
                            // fetch default entity Info
                            let getContactTemplate = this.currentEntity && this.currentEntity.templates && this.currentEntity.templates[0];
                            let getContactTemplateData = this.getTemplatesData.filter(tempData => {
                                return tempData.template_id === getContactTemplate.template_id;
                            })
                            if (getContactTemplateData && getContactTemplateData.length) {
                                // customer = getContactTemplateData[0].template_data;
                                customer = {
                                    email: getContactTemplateData[0]?.template_data?.email,
                                    name: getContactTemplateData[0]?.template_data?.name,
                                    address: getContactTemplateData[0]?.template_data?.address,
                                    city: '',
                                    state: '',
                                    country: '',
                                    zip_code: ''
                                }
                            }
                        }
                        let entityId = null;
                        let templateId = null;
                        if (this.type === "APP_USER") {
                            /*It will Update the Application user Profile data **/
                            this.$bus.$emit('pay-button-action-app-user', 'UPDATE');
                            entityId = this.currentEntity._id;
                            templateId = this.currentStep.template_id;
                        }
                        else if (this.type === "FORM_BUILDER_ENTITY_TEMPLATES") {
                            entityId = this.currentEntity._id;
                            templateId = this.currentStep.template_id;
                            this.$bus.$emit('pay-button-action-form-builder-entity', {
                                type: 'FORM_BUILDER_ENTITY_UPDATE',
                                action: false,
                                form: this.form,
                                busKey: this.data.key
                            })
                        }
                        else if (this.type === "FORM_BUILDER_TEMPLATE") {
                            entityId = this.currentEntity._id;
                            templateId = this.currentStep.template_id;
                            this.$bus.$emit('pay-button-action-form-builder-template', {
                                type: 'FORM_BUILDER_TEMPLATE_UPDATE',
                                action: false,
                                form: this.form
                            })
                        } else {
                            entityId = this.currentEntity._id;
                            templateId = this.currentStep.template_id;
                            this.$bus.$emit('pay-button-action', {
                                action: 'UPDATE',
                                entityId,
                                templateId,
                                entityDataId: this.entityDataId
                            });
                        }
                        this.loading = true;
                        this.loadingText = "Data is saving......";
                        let location = urlParser(window.location.href);
                        setTimeout(() => {
                            this.paymentActionAfterEntityDataCreated(decryptedData.apiKey, paymentAccountInfo, this.entityDataId, location, customer, entityId, templateId);
                        }, 1500);
                    } else {

                        this.loading = true;
                        this.loadingText = "Saving the data...... wait a while....."
                        let location = urlParser(window.location.href);
                        if (this.type === "FORM_BUILDER_TEMPLATE") {

                            //form builder Data
                            // location = urlParser(window.location.href);
                            this.$bus.$emit('pay-button-action-form-builder-template', {
                                type: 'FORM_BUILDER_TEMPLATE_UPDATE',
                                action: true,
                                form: this.form,
                                apiKey: decryptedData.apiKey,
                                paymentSystem: paymentAccountInfo,
                                location
                            });
                        } else if (this.type === "FORM_BUILDER_ENTITY_TEMPLATES") {
                            // location = urlParser(window.location.href);
                            this.$bus.$emit('pay-button-action-form-builder-entity', {
                                type: 'FORM_BUILDER_ENTITY_UPDATE',
                                action: true,
                                form: this.form,
                                apiKey: decryptedData.apiKey,
                                paymentSystem: paymentAccountInfo,
                                location,
                                busKey: this.data.key
                            })
                        } else {
                            await this.saveEntityData();
                            await sleep(200);
                            if (this.getNewEntityData?._id) {
                                if (this.isApplicationUserSide) {
                                    let menuId = this.$route.params.menuId || this.$route.query.menu_id;
                                    let filter = this.$route.query.filter || '';
                                    location = urlParser(`${Config.APP_URL}/ap/entity-execute/${this.currentEntity._id}?key=${Math.floor(Math.random() * 100000)}&dataId=${this.getNewEntityData._id}&filter=${filter}&menu_id=${menuId}&layout=STANDARD&page=1&pageSize=10&type=AUfYWb7SC6U%3D`)
                                } else if (this.$route.name == "GuestFormbuilderExecute" || this.$route.name == "GuestFormbuilderExecuteStep") {
                                    location = urlParser(Config.APP_URL + this.$route.fullPath);
                                } else {
                                    location = urlParser(`${Config.APP_URL}/entity/edit/${this.currentEntity._id}?key=${Math.floor(Math.random() * 100000)}&dataId=${this.getNewEntityData._id}&filter=&layout=STANDARD`);
                                }
                                await sleep(100);
                                this.paymentActionAfterEntityDataCreated(decryptedData.apiKey, paymentAccountInfo, this.getNewEntityData._id, location, {}, this.currentEntity._id, this.currentStep.template_id)
                            }
                        }
                        this.declineUrl = location;
                    }
                    // this.loading = false;
                    // this.loadingText = "Loading ......"
                }
            }
            catch (err) {
                console.log("save data & init Payment", err)
            }

            // this.loading = false;

        },
        async payInitiate() {
            if (this.payAmount) {
                // this.payAmount = Number(parseFloat(this.payAmount).toFixed(2));
                // this.paymentAction(...this.tempStorage);
                this.paymentAction(this.tempStorage.apiKey, this.tempStorage.sessionData, this.tempStorage.entityDataId, this.tempStorage.location, this.tempStorage.customer, this.tempStorage.entityId, this.tempStorage.templateId, this.tempStorage.type, this.tempStorage.formBuilderDataId, this.tempStorage.formBuilderId);
            } else {
                alert("Please enter payable amount");
            }
        },
        async paymentActionAfterEntityDataCreated(apiKey, sessionData, entityDataId, location, customer, entityId, templateId, type = false, formBuilderDataId = '', formBuilderId = '') {
            this.loading = true;
            this.loadingText = "Init the transaction";
            this.tempStorage = {
                apiKey,
                sessionData,
                entityDataId,
                location,
                customer,
                entityId,
                templateId,
                type: type,
                formBuilderDataId: formBuilderDataId,
                formBuilderId: formBuilderId,
                amount: this.payAmount,
                currency: this.data.validations.currency
            }
            await sleep(150);
            this.paymentAction(this.tempStorage.apiKey, this.tempStorage.sessionData, this.tempStorage.entityDataId, this.tempStorage.location, this.tempStorage.customer, this.tempStorage.entityId, this.tempStorage.templateId, this.tempStorage.type, this.tempStorage.formBuilderDataId, this.tempStorage.formBuilderId);
            // }
        },
        async paymentAction(apiKey, sessionData, entityDataId, location, customer, entityId, templateId, type = false, formBuilderDataId = '', formBuilderId = '') {
            //Redirection Url 
            this.loading = true;
            // this.loadingText = "Redirecting to payment gateway";
            this.newPaymentScreen = false;
            this.customerInfo = customer;
            console.log("Customer", customer, type);
            if (this.data && this.data.validations && this.data.validations.redirectUrl) {
                this.data.validations.defaultRedirectUrl = '/entity-payment-status-update?SessionData=' + sessionData._id + '&location=' + window.btoa(this.data.validations.redirectUrl) + '&temp=' + templateId;
            } else {
                this.data.validations.defaultRedirectUrl = '/entity-payment-status-update?SessionData=' + sessionData._id + '&location=' + window.btoa(location) + '&temp=' + templateId;
            }
            //add customer to payment link
            /* Modes validation As of now we have configured Card and ACH for USA ("ACH", "CREDIT CARD") **/
            let payModes = [];
            if (this.data && this.data.validations && this.data.validations.payModes && this.data.validations.payModes.length) {
                this.data.validations.payModes.forEach((mode) => {
                    if (mode == 'CREDIT CARD') {
                        payModes.push("CARD")
                    } else if (mode == 'ACH') {
                        payModes.push("ACH_LEGACY")
                    }
                })
            } else {
                payModes.push("CARD")
            }

            /* payment link generation Description for further our system usage **/
            let description = {
                entityDataId: entityDataId,
                location: this.data && this.data.validations && this.data.validations.redirectUrl ? this.data.validations.redirectUrl : location,
                // entityId: this.currentEntity && this.currentEntity._id,
                entityId: entityId,
                // templateId: this.currentStep &&this.currentStep.template_id,
                templateId: templateId,
                key: this.data.key,
                amount: this.totalPayAmount ? this.totalPayAmount : Number(parseFloat(this.getValue).toFixed(2)),
                paymentInit: this.finalAmount,
                currency: this.data.validations.currency,
                type: formBuilderDataId && formBuilderId ? this.category : '',
                category: this.category,
                formBuilderDataId: formBuilderDataId,
                formBuilderId: formBuilderId,
                isChildDataTable: this.isFromChildTable,
                transactions: this.childEntitySettings && this.childEntitySettings.dataSet || []
            };
            let bytes = await this.$CryptoJS.AES.decrypt(sessionData.crypto, sessionData.ivKey);
            let decryptedData = JSON.parse(bytes.toString(this.$CryptoJS.enc.Utf8));
            this.tempStorage.description = description;
            if (sessionData.pgType == "RAZORPAY") {
                let busInfo = this.$bus;
                this.newPaymentScreen = false;
                this.loading = false;
                const res = await this.loadScript(
                    'https://checkout.razorpay.com/v1/checkout.js'
                );
                if (!res) {
                    alert('Razorpay SDK failed to load. Are you online?');
                    return;
                }
                let data = {
                    amount: Number(parseFloat(description.paymentInit * 100).toFixed(2)),
                    fee: 0,
                    currency: this.data.validations.currency,
                    description: this.data.description,
                    redirectUrl: this.data.validations && this.data.validations.defaultRedirectUrl ? Config.APP_URL + this.data.validations.defaultRedirectUrl : location,
                    modes: payModes,
                    optionItems: description,
                    customer: customer,
                }
                const result = await axios.post(`${Config.PAYMENT_GATEWAY_URL}/razorpay/order?pgAccountId=${sessionData.pg_account_id}&txnAcId=${sessionData._id}`, data);

                if (!result) {
                    alert('Server error. Are you online?');
                    return;
                }

                const {
                    amount,
                    id: order_id,
                    currency
                } = result.data;
                const options = {
                    key: decryptedData.publicKey, // Enter the Key ID generated from the Dashboard
                    amount: amount.toString(),
                    currency: currency,
                    name: this.getCompanyDetails && this.getCompanyDetails.legal_name ? this.getCompanyDetails.legal_name : this.getContactLoginSlug && this.getContactLoginSlug.legal_name ? this.getContactLoginSlug.legal_name : "ESIGNS",
                    description: data.description,
                    image: this.getCompanyDetails && this.getCompanyDetails.logo ? `${Config.S3_BUCKET_URL}/${this.getCompanyDetails.logo}` :
                        this.getContactLoginSlug && this.getContactLoginSlug.logo ? `${Config.S3_BUCKET_URL}/${this.getContactLoginSlug.logo}` :
                            "https://esigns.io/images/logo-dark.png",
                    order_id: order_id,
                    handler: async function (response) {
                        let successTimer;
                        Swal.fire({
                            title: "Validating.....",
                            html: `Verifying the payment.... please wait while `,
                            timer: 100000,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            didOpen: () => {
                                Swal.showLoading();
                            },
                            willClose: () => {
                                clearInterval(successTimer);
                                // this.logout();
                            },
                        });

                        const successData = await axios.get(`${Config.PAYMENT_GATEWAY_URL}/razorpay/verify/${response.razorpay_payment_id}?pgAccountId=${sessionData.pg_account_id}&txnAcId=${sessionData._id}`);
                        if (successData.status === 200) {
                            Swal.close()
                            let timerInterval;
                            Swal.fire({
                                title: "Processing...",
                                html: `We're verifying the status of your payment.It may take few minutes to receive payment status and update your  status.Please do not close this window, as it will impact your payment.`,
                                timer: 100000,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                                didOpen: () => {
                                    Swal.showLoading();
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);
                                    // this.logout();
                                },
                            });
                            let data = successData.data
                            let params = {
                                ...data.notes,
                                type: this.category,
                                status: "SUCCESS",
                                txnDetails: {
                                    txn_id: data.id,
                                    gateway: "RAZORPAY",
                                    paymentId: data.order_id,
                                    name: '',
                                    email: data.email,
                                    phoneNumber: data.contact,
                                    paymentSession: response.razorpay_signature,
                                    amount: data.amount || 0,
                                    amountRefunded: data.amount_refunded || 0,
                                    description: "",
                                    paymentDate: moment.utc(),
                                    refundDate: '',
                                    status: data.status == "captured" ? 'PAID' : 'NOT PAID',
                                    paymentType: data.method || "CARD",
                                    method: data.method || '',
                                    currency: data.currency || 'USD',
                                    authCode: '',
                                    authorizationId: '',
                                }
                            }
                            let apiResponse = await systemAxios.post(`/updateRulesOfPayBtn`, params);
                            if (apiResponse.data) {
                                setTimeout(() => {
                                    if (params.category === 'FORM_BUILDER') {
                                        Swal.close();
                                        // Refresh the form builder data
                                        busInfo.$emit("refreshFormbuilderAfterPayment", data.notes)
                                        // Swal.close();
                                    }
                                    else {
                                        window.location.href = urlParser(data.notes.location);
                                        Swal.close();
                                    }
                                }, 3000);
                            }

                        } else {
                            alert(successData.status + ' Error');
                        }

                    },
                    modal: {
                        ondismiss: function () {
                            console.log("Razorpay Checkout form closed");
                            window.location.href = urlParser(description.location);
                        }
                    },
                    prefill: {
                        name: customer.name,
                        email: customer.email,
                        // contact: customer.phoneNumber,
                    },
                    notes: {
                        address: '',
                    },
                    theme: {
                        color: this.getCompanyDetails && this.getCompanyDetails.button && this.getCompanyDetails.button.background ? this.getCompanyDetails.button.background : this.getContactLoginSlug && this.getContactLoginSlug.button && this.getContactLoginSlug.button.background ? this.getContactLoginSlug.button.background : '#61dafb',
                    },
                };
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            }
            else if (sessionData.pgType == "STRIPE") {
                //Need add description variable here for redirect 
                // this.tempStorage.description = description;
                const res = await this.loadScript(
                    'https://js.stripe.com/v3/'
                );
                if (!res) {
                    alert('Stripe SDK failed to load. Are you online?');
                    return;
                }
                await sleep(200);
                this.newPaymentScreen = true;
                this.loading = false;
            }
            else if (sessionData.pgType == "PAYPAL") {

                let currency = this.data.validations.currency;
                let client = sessionData.publicKey;
                //Need add description variable here for redirect 
                // &buyer-country=${country}
                const res = await this.loadScript(
                    `https://www.paypal.com/sdk/js?client-id=${client}&currency=${currency}&components=buttons&enable-funding=venmo`
                );
                if (!res) {
                    alert('Paypal SDK failed to load. Are you online?');
                    return;
                }
                await sleep(200);
                this.newPaymentScreen = true;
                this.loading = false;
            }
            else {
                this.newPaymentScreen = true;
                this.loading = false;
            }

        },
        loadScript(src) {
            try {
                return new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.src = src;
                    script.onload = () => {
                        resolve(true);
                    };
                    script.onerror = () => {
                        resolve(false);
                    };
                    document.body.appendChild(script);
                });
            }
            catch (err) {
                console.log("Loading Script Failed", err)
            }
        },
        async saveEntityData() {
            try {
                this.loading = true;
                this.loadingText = "Saving your data .... Please wait......";
                let form = {};
                let templateId = this.templateData && this.templateData._id ? this.templateData._id : this.currentStep && this.currentStep.template_id ? this.currentStep.template_id : ""
                if (templateId) {
                    if (this.hasRepeatableTemplate) {
                        let key = "";
                        if (
                            this.entityRepeatableTemplateData &&
                            this.entityRepeatableTemplateData.templateInfo &&
                            this.entityRepeatableTemplateData.templateInfo.name
                        ) {
                            let name = this.entityRepeatableTemplateData.templateInfo.name;
                            key = name
                                .toLowerCase()
                                .split(" ")
                                .join("_");
                        } else {
                            key = "templateData";
                        }
                        form[key] = this.entitiesData.map(e => {
                            if (e.isNewData) {
                                delete e.isNewData;
                            }
                            return e;
                        });
                    } else {
                        // form = this.form;
                        let incrementFields = this.fieldsData.filter(
                            e => e.input_type == "AUTO_INCREMENT_NUMBER"
                        );

                        if (incrementFields && incrementFields.length) {
                            await incrementFields.map(async field => {
                                this.form[`${field.key}_info`] = {
                                    number: field.auto_increment_starting_number,
                                    prefix: field.auto_increment_prefix
                                };
                            });
                        }

                        form = await this.prepareFormData();
                        if (
                            form &&
                            form.first_name &&
                            form.last_name &&
                            form.first_name.length &&
                            form.last_name.length &&
                            !form.name
                        ) {
                            form.name = form.first_name + " " + form.last_name;
                        }
                    }
                    let data = {
                        template_id: templateId,
                        // template_id:this.templateData._id,
                        template_data: form,
                        user_id: this.getNewFormbuilderData,
                        entity_id: this.getEntityId,
                        template_completion_status: false,
                        entity_data_id: null
                    };
                    await this.$store.dispatch(
                        "templatesData/createEntityDataByTemplateData",
                        data
                    );

                    if (this.getEntityDataCreateByTemplateStatus) {
                        this.loading = false;
                        this.$notify.success({
                            title: "Success",
                            message: "Data saved successfully"
                        });

                        if (this.getNewEntityData?._id) {
                            /* Relation ship Data functions */
                            // this.checkAndSetRelationship(this.getNewEntityData?._id);
                            // we have to do entity stuff
                            // await this.updateEntitiesData();

                        }
                    } else {
                        if (
                            this.getNewEntityCreateError &&
                            this.getNewEntityCreateError.message
                        ) {
                            this.$notify.error({
                                title: "Error",
                                message: this.getNewEntityCreateError.message
                            });
                        } else {
                            this.$notify.error({
                                title: "Error",
                                message: "Error at saving data"
                            });
                        }
                        this.loading = false;
                    }
                } else {
                    this.loading = false;
                    this.$notify.error({
                        title: "Error",
                        message: "Failed to find the current step information"
                    });
                }
                this.loading = false;

            } catch (err) {
                console.log("saveEntityData", err);
            }
        },
        async prepareFormData() {
            for (let [key, value] of Object.entries(this.form)) {
                if (
                    value &&
                    typeof value == "object" &&
                    value.type == "DOCUMENT" &&
                    value.new == true &&
                    value.files
                ) {
                    this.loading = true;
                    this.loadingText = "Uploading files...";
                    let path = await this.uploadFile(value.files);
                    this.form[key].path = path;
                    this.form[key].new = false;
                }
            }
            return this.form;
        },
        async uploadFile(file) {
            var formData = new FormData();
            file.forEach((el) => {
                formData.append("files", el.raw !== undefined ? el.raw : el);
            });
            formData.append("path", "template-data-documents");
            let filesData = await axios.post(`/presignMultiUpload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log(filesData);
        },

        applyFormRules() {
            this.$emit("applyFormRules");
        },
        async setGlobalVariableData() {
            let params = {
                id: this.data.global_variable_id
            };
            await this.$store.dispatch(
                "globalVariables/fetchGlobalVariableById",
                params
            );

            if (this.getGlobalVariableById) {
                if (this.getGlobalVariableById.input_type === "SINGLE_LINE_TEXT") {
                    this.isList = true;
                    this.form[this.data.key] = this.getGlobalVariableById.value;
                }
            }
        },
        readonly() {
            //If it's an entity varaible, have to check access
            if (
                this.data.field_assignable === "read_only" &&
                this.data.is_entity_variable
            ) {
                return true;
            }
            if (
                this.data &&
                this.data.properties &&
                this.data.properties.filed_content === "READ ONLY"
            ) {
                return true;
            }
        },
        checkReadonly() {
            if (this.checkIsDisabled) {
                return true;
            }
            if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
                if (this.data.styles && this.data.styles.labelStyle) {
                    if (this.data.styles.labelStyle == "hide") {
                        this.showLabel = false;
                    }

                }
                return !this.fieldFilledByCurrentUser(this.data.filled_by);
            } else {
                if (
                    this.data &&
                    this.data.properties &&
                    this.data.properties.filed_content === "DISABLED"
                ) {
                    return true;
                }
                return !this.fieldFilledByCurrentUser(this.data.filled_by);
            }
        },
        paymentHistory() {
            this.loading = true;
            this.partialPaymentVisible = false;
            this.dialogLoader = true;
            this.historyPopUp = true;
            this.modalTitle = 'Payment History';
            this.loading = false;
            this.dialogLoader = false;

        },
        closePopUps() {
            this.loading = true;
            this.partialPaymentVisible = false;
            this.dialogLoader = false;
            this.historyPopUp = false;
            this.modalTitle = '';
            this.loading = false;
        }

    },
    watch: {
        "form": {
            handler: function () { },
            deep: true,
        },
    }
};
</script>

<style lang="scss" scoped>
.icons {
    width: 100%;
}

.gateway_icon {
    width: 130px;
    height: 40px;

    @media (min-width:320px) {
        position: relative;
        right: 15px;
    }
}

.disabled-field {
    background-color: #F5F7FA;
    border-color: #E4E7ED;
    color: #C0C4CC;
    /* Set your preferred greyed-out color */
    pointer-events: painted;
    cursor: not-allowed;
}

.paymentDialog {
    .el-dialog__wrapper {
        .el-dialog__header {
            background: transparent !important;
            //border-bottom: none;
            padding: 0;
            //border-radius: 5px 5px 0 0;
        }
    }
}

.swal2-container {
    z-index: 2048 !important;
}
</style>
<style lang="scss">
.swal2-container {
    z-index: 2048 !important;
}

@media screen and (max-width: 678px) {
    h2 {
        font-weight: 300px;
        font-size: 1em;
    }
}
</style>
